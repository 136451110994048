import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import { cloneDeep } from 'lodash-es';
import {
	setLocalStorageItem,
	getLocalStorageItem,
	removeLocalStorageItem,
	EStorageKey,
} from '@/utils/localStorage';
import { useAuthService } from '@/api/authService';
import type {
	AuthToken,
	BasicAuthRequest,
	TeamworksAuthRequest,
	AxleAuthRequest,
	ImpersonationAuthRequest,
} from '@/types/auth';

export const isAuthenticated = () => !!getLocalStorageItem<AuthToken>('inflcr-token');

export const isImpersonating = () => !!getLocalStorageItem<AuthToken>('inflcr-impersonation-token');

export const useAuthStore = defineStore('auth', () => {
	const DEFAULT_STATE = {
		token: getLocalStorageItem<AuthToken>('inflcr-token'),
		impersonationToken: getLocalStorageItem<AuthToken>('inflcr-impersonation-token'),
	};

	const state = reactive(cloneDeep(DEFAULT_STATE));

	const setToken = (token: AuthToken, rememberMe: boolean) => {
		state.token = token;
		setLocalStorageItem<AuthToken>(
			EStorageKey.TOKEN,
			token,
			new Date(token.expires_at),
			rememberMe ? 'localStorage' : 'sessionStorage',
		);
	};

	const setImpersonationToken = (token: AuthToken) => {
		state.impersonationToken = token;
		setLocalStorageItem<AuthToken>(
			EStorageKey.IMPERSONATION_TOKEN,
			token,
			new Date(token.expires_at),
			'sessionStorage',
		);
	};

	const getTokenBasic = async (authTokenRequest: BasicAuthRequest) => {
		const authService = useAuthService();
		const token = await authService.getTokenBasic(authTokenRequest);
		if (!token) throw new Error();
		setToken(token, authTokenRequest.remember_me);
	};

	const getTokenTeamworks = async (authTokenRequest?: TeamworksAuthRequest) => {
		if (!authTokenRequest) return;
		const authService = useAuthService();
		const token = await authService.getTokenTeamworks(authTokenRequest);
		if (!token) throw new Error();
		setToken(token, authTokenRequest.remember_me);
	};

	const getTokenAxle = async (authTokenRequest?: AxleAuthRequest) => {
		if (!authTokenRequest) return;
		const authService = useAuthService();
		const token = await authService.getTokenAxle(authTokenRequest);
		if (!token) throw new Error();
		setToken(token, true);
	};

	const getTokenImpersonation = async (authTokenRequest: ImpersonationAuthRequest) => {
		const authService = useAuthService();
		const token = await authService.getTokenImpersonation(authTokenRequest);
		if (!token) throw new Error();
		setImpersonationToken(token);
	};

	const removeToken = async (tokenUuid: string) => {
		try {
			const authService = useAuthService();
			await authService.deleteToken(tokenUuid);
		} catch (error) {
			console.error(error);
		} finally {
			clearClientToken();
		}
	};

	const clearClientToken = () => {
		state.token = null;
		removeLocalStorageItem('inflcr-token');
	};

	const removeImpersonationToken = async (tokenUuid: string) => {
		try {
			const authService = useAuthService();
			await authService.deleteToken(tokenUuid);
		} catch (error) {
			console.error(error);
		} finally {
			clearClientImpersonationToken();
		}
	};

	const clearClientImpersonationToken = () => {
		state.impersonationToken = null;
		removeLocalStorageItem('inflcr-impersonation-token');
	};

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setToken,
		getTokenBasic,
		getTokenTeamworks,
		getTokenAxle,
		getTokenImpersonation,
		removeToken,
		clearClientToken,
		removeImpersonationToken,
		$reset,
	};
});
