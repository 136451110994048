<template>
	<VNavigationDrawer
		ref="navigationDrawer"
		v-model="isActive"
		class="the-navigation-menu pt-8 text-max-emphasis"
		:rail="!mobile && !isExpanded"
		theme="INFLCRDark"
		width="270"
		rail-width="80"
		:touchless="true"
		floating
		color="axle-navbar-top"
	>
		<template #prepend>
			<div class="mb-4 mb-md-6 px-5">
				<VIcon
					color="primary"
					icon="$twi-inflcr-hex"
					size="36"
				/>
			</div>
			<ProfileSwitcher :is-expanded="isExpanded" />
		</template>
		<div class="d-flex flex-column align-stretch the-navigation-menu__content">
			<VList
				class="px-5"
				density="compact"
				nav
			>
				<VListItem
					v-if="!mobile"
					:prepend-icon="isExpanded ? 'close' : 'menu'"
					:title="t(`navigationMenu.${ isExpanded ? 'closeMenu' : 'expandMenu' }`)"
					data-testid="mobileButtonToggleMenu"
					@click="isExpanded = !isExpanded"
				>
					<VTooltip
						v-if="!isExpanded"
						activator="parent"
						location="right"
					>
						{{ t('navigationMenu.expandMenu') }}
					</VTooltip>
				</VListItem>
				<TheNavigationMenuItem
					v-for="item in mainItems"
					:key="item.id"
					:nav-item="item"
					:is-expanded="isExpanded"
				/>
			</VList>
			<HelpMenu
				class="mt-auto"
				:is-expanded="isExpanded"
			/>
			<AppSwitcherMenu
				:is-expanded="isExpanded"
			/>
		</div>
		<template #append>
			<UserMenu
				:is-expanded="isExpanded"
			/>
		</template>
		<template #image>
			<div class="the-navigation-menu__burst">
				<ImageBackgroundBurst />
			</div>
		</template>
	</VNavigationDrawer>
</template>

<script setup lang="ts">
import ImageBackgroundBurst from '@/components/ImageBackgroundBurst.vue';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { usePrimaryNavigation } from '@/composables/usePrimaryNavigation';
import ProfileSwitcher from './TheNavigationMenuProfileSwitcher.vue';
import TheNavigationMenuItem from './TheNavigationMenuItem.vue';
import AppSwitcherMenu from './TheNavigationMenuAppSwitcher.vue';
import HelpMenu from './TheNavigationMenuHelpMenu.vue';
import UserMenu from './TheNavigationMenuUserMenu.vue';

const isActive = defineModel<boolean>({ default: false });

const { t } = useI18n();
const { mainItems } = usePrimaryNavigation();

const isExpanded = ref(true);

const { mobile } = useDisplay();
onBeforeMount(async () => {
	if (!mobile.value) {
		window.requestAnimationFrame(() => {
			isActive.value = true;
		});
	}
});
</script>

<style scoped lang="scss">
.the-navigation-menu {
	transition:background-color 0.2s;
	:deep(.v-navigation-drawer__img){
		overflow: hidden;
	}
	:deep(.the-navigation-menu__burst){
		color:rgb(var(--v-theme-axle-navbar-bottom));
		position: absolute;
		bottom: -8px;
		left: 0;
		width: 270px;
		transition:color 0.2s;
	}
	&__content{
		min-height: 100%;
	}
}
</style>
