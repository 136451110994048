import { reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export type ConfirmationDialogOptions = {
	title: string;
	description?: string;
	descriptionHtml?: string;
	confirmText?: string;
	cancelText?: string;
	confirmColor?: 'black' | 'success' | 'error' | 'warning' | 'info';
	reset?: boolean;
} & ({ description: string } | { descriptionHtml: string });
interface UseConfirmationDialogState {
	isOpen: boolean;
	options: ConfirmationDialogOptions | undefined;
	resolve: ((value: boolean) => void) | undefined;
	buttonsDisabled: boolean;
	isLoading: boolean;
}

const DEFAULT_STATE: UseConfirmationDialogState = {
	isOpen: false,
	options: undefined,
	resolve: undefined,
	buttonsDisabled: true,
	isLoading: false,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseConfirmationDialogState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
});

export function useConfirmationDialog() {
	const { t } = useI18n();

	const defaultOptions: ConfirmationDialogOptions = {
		title: '',
		description: '',
		confirmText: t('yes'),
		cancelText: t('cancel'),
		confirmColor: 'error',
		reset: true,
	};

	function open(options: ConfirmationDialogOptions) {
		state.options = { ...defaultOptions, ...options };
		state.buttonsDisabled = false;
		state.isOpen = true;
		return new Promise<boolean>((resolve) => {
			state.resolve = resolve;
		});
	}

	function close() {
		return new Promise((resolve) => {
			state.buttonsDisabled = true;
			state.isOpen = false;
			setTimeout(resolve, 250);
		});
	}

	async function reset() {
		await close();
		resetState();
	}

	function respond(answer: boolean) {
		if (answer) state.isLoading = true;
		state.resolve?.(answer);
		if (!answer || state.options?.reset) reset();
	}

	return {
		state,
		open,
		reset,
		respond,
	};
}
