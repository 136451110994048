<template>
	<VProgressLinear />
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAxleAuth }	from '@/composables/useAxleAuth';
import { useBasicAuth } from '@/composables/useBasicAuth';

const router = useRouter();
const route = useRoute();

const { logOut } = useBasicAuth();

const {
	axleTokenRequest,
	logInAxle,
} = useAxleAuth({ onLogInSuccess, onLogInError });

function onLogInSuccess() {
	router.push({ name: 'ProfileChooserIndex' });
}

function onLogInError() {
	router.push({ name: 'LoginIndex' });
}


async function logIn() {
	await logOut({ redirect: false });
	axleTokenRequest.token = route.query.token as string;
	logInAxle();
}

onBeforeMount(logIn);
</script>
