import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { cloneDeep, sortBy } from 'lodash-es';
import type { SortParam } from '@/api/utilities/provider';
import type { UserList, UserListsSortValue } from '@/types/userLists';
import { useUserListsService } from '@/api/userListsService';


interface UserListsStoreState {
	userLists: Omit<UserList, 'user_profile_count' | 'user_profiles'>[];
	userListsPage: number;
	userListsSearch: string;
	userListsSort: SortParam<UserListsSortValue>[];
}

export const getDefaultSort = (): SortParam<UserListsSortValue>[] => [{ key: 'user_list_users_count', order: 'desc' }];

export const useUserListsStore = defineStore('lists', () => {
	const { getUserLists } = useUserListsService();

	const DEFAULT_STATE = {
		userLists: [],
		userListsPage: 1,
		userListsSearch: '',
		userListsSort: getDefaultSort(),
	};

	const state = reactive<UserListsStoreState>(cloneDeep(DEFAULT_STATE));

	const setUserLists = async () => {
		const { data } = await getUserLists({
			all: true,
			include_users: false,
		});
		state.userLists = data;
	};

	const setUserList = (list: Omit<UserList, 'user_profile_count' | 'user_profiles'>) => {
		const index = state.userLists.findIndex(({ uuid }) => list.uuid === uuid);
		index > -1 ? state.userLists[index] = list : state.userLists.push(list);
	};

	const setUserListsSearch = (search: string) => {
		state.userListsSearch = search;
	};

	const setUserListsSort = (sort: SortParam<UserListsSortValue>[]) => {
		state.userListsSort = sort;
	};

	const setUserListsPage = (page: number) => {
		state.userListsPage = page;
	};

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	const userListsSorted = computed(() => {
		return sortBy(state.userLists, 'name');
	});

	return {
		...toRefs(state),
		setUserLists,
		setUserList,
		setUserListsPage,
		setUserListsSearch,
		setUserListsSort,
		userListsSorted,
		$reset,
	};
});
