import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import type { Toast } from '@/composables/useToast';
import { cloneDeep } from 'lodash-es';

interface MainStoreState {
	isAppLoading: boolean;
	toasts: Toast[];
}

export const useMainStore = defineStore('main', () => {
	const DEFAULT_STATE = {
		toasts: [],
		isAppLoading: false,
	};

	const state = reactive<MainStoreState>(cloneDeep(DEFAULT_STATE));

	const abortControllers = reactive<Record<string, AbortController>>({});

	const setToast = (toast: Omit<Toast, 'uuid'>) => {
		state.toasts.push({ ...toast, uuid: crypto.randomUUID() });
	};

	const removeToast = (uuid: string) => {
		const index = state.toasts.findIndex((toast) => toast.uuid === uuid);
		if (index > -1) state.toasts.splice(index, 1);
	};

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setToast,
		removeToast,
		abortControllers,
		$reset,
	};
});
