<template>
	<VApp>
		<Component
			:is="layouts[layout]"
			:options="layoutOptions"
		>
			<RouterView />
		</Component>
		<ToastsOverlay />
		<ConfirmationDialog />
		<TermsOfUseDialog />
		<MediaPreviewDialog
			v-if="isMediaPreviewDialogRendered"
			v-model="mediaPreviewDialogState.isOpen"
			v-model:index="mediaPreviewDialogState.currentIndex"
			:actions-menu-items="mediaPreviewDialogState.actionsMenuItems"
			:download="mediaPreviewDialogState.download"
			:enable-increment="mediaPreviewDialogState.enableIncrement"
			:media="mediaPreviewDialogState.media"
			@view-details="mediaPreviewDialogState.onViewDetails"
		/>
	</VApp>
</template>

<script setup lang="ts">
import { onBeforeMount, defineAsyncComponent } from 'vue';
import { useHead } from '@unhead/vue';
import { getLocalStorageItem } from '@/utils/localStorage';
import { initGainsight } from '@/plugins/gainsight';
import TermsOfUseDialog from '@/components/TermsOfUseDialog.vue';
import { useLayouts } from '@/composables/useLayouts';
import { useLocales } from '@/composables/useLocales';
import { useMediaPreviewDialog } from './composables/useMediaPreviewDialog';
import { getRouteTitle } from './composables/setTitleTag';
import ToastsOverlay from '@/components/ToastsOverlay.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

// MEDIA PREVIEW
const MediaPreviewDialog = defineAsyncComponent(() => import('@/components/MediaPreviewDialog.vue'));
const {
	state: mediaPreviewDialogState,
	isRendered: isMediaPreviewDialogRendered,
} = useMediaPreviewDialog();


const { layout, layouts, layoutOptions } = useLayouts();
const { setLocale } = useLocales();
const routeTitle = getRouteTitle();

useHead({
	templateParams: {
		site: { name: 'Influencer' },
		separator: '|',
	},
	titleTemplate: '%s %separator %site.name',
	title: () => routeTitle.value,
});

onBeforeMount(() => {
	setLocale(getLocalStorageItem<string>('inflcr-app-locale') || window.navigator.language);
	initGainsight();
});
</script>
