<template>
	<VListItem
		ref="theNavigationMenuItem"
		class="text-decoration-none"
		v-bind="attrs"
		:data-testid="`theNavigationMenuItem_${camelCase(navItem.title)}`"
		@click="navItem.action"
	>
		<template
			v-if="navItem.prependIcon"
			#prepend
		>
			<VBadge
				:model-value="isIconBadgeVisible"
				:content="iconBadgeContent"
				color="error"
			>
				<VIcon :icon="navItem.prependIcon" />
			</VBadge>
		</template>
		<template
			v-if="isExpanded && navItem.badge"
			#append
		>
			<VBadge
				color="white"
				:content="navItem.badge"
				inline
			/>
		</template>
		<VTooltip
			v-if="!isExpanded && tooltipVisible"
			ref="theNavigationMenuItemTooltip"
			activator="parent"
			location="right"
		>
			{{ navItem.title }}
		</VTooltip>
	</VListItem>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { whenever } from '@vueuse/core';
import { camelCase } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import type { PrimaryNavigationItem } from '@/composables/usePrimaryNavigation';
import { watch } from 'vue';

const props = withDefaults(defineProps<{
	navItem: PrimaryNavigationItem;
	isExpanded: boolean;
	tooltipVisible?: boolean;
}>(), {
	tooltipVisible: true,
});

const attrs = computed(() => {
	const attrs = {
		title: props.navItem.title,
		appendIcon: props.navItem.appendIcon,
		disabled: !props.navItem.enabled,
	};

	if (props.navItem.to) {
		Object.assign(attrs, {
			to: props.navItem.to,
		});
	}

	if (props.navItem.link) {
		Object.assign(attrs, {
			href: props.navItem.link,
			target: '_blank',
			appendIcon: 'open_in_new',
		});
	}

	return attrs;
});

const receivedMessages = ref<number>(0);
const isIconBadgeVisible = computed<boolean>(() => receivedMessages.value > 0);
const iconBadgeContent = computed<string>(() => {
	return receivedMessages.value > 99
		? '99+'
		: receivedMessages.value.toString();
});

const isConversationMenuItem = props.navItem.to?.name === 'ExchangeConversations';

if (isConversationMenuItem) {
	const { receivedConversationEvent } = storeToRefs(useUserStore());

	const route = useRoute();
	const isConversationRoute = computed(() => route.path.startsWith('/exchange/conversations'));

	whenever(receivedConversationEvent, () => {
		if (isConversationRoute.value) return;
		receivedMessages.value++;
	});

	watch(() => route.name, () => {
		if (!isConversationRoute.value) return;
		receivedMessages.value = 0;
	});
}
</script>
