// function from https://stackoverflow.com/a/5624139/3695983
function hexToRgb(hexColor: string) {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hexColor = hexColor.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
	return result
		? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		}
		: null;
}

function hexToRgbValues(hexColor: string | undefined) {
	if (!hexColor) return null;
	const color = hexToRgb(hexColor);
	if (!color) return null;
	return `${color.r}, ${color.g}, ${color.b}`;
}


function rgbToHex(r: number, g: number, b: number): string {
	const toHex = (c: number) => {
		const hex = c.toString(16);
		return hex.length == 1 ? '0' + hex : hex;
	};
	return '#' + toHex(r) + toHex(g) + toHex(b);
}

function rgbValuesToHex(rgbValues: string) {
	const [r, g, b] = rgbValues.split(',').map((v) => parseInt(v));
	return rgbToHex(r, g, b);
}


const lightDefaultHex = '#ffffff';
const darkDefaultHex = '#000000';
const lightDefaultRgbValues = hexToRgbValues(lightDefaultHex) as string;
const darkDefaultRgbValues = hexToRgbValues(darkDefaultHex) as string;

// function from https://stackoverflow.com/a/9733420/3695983
function luminance(r: number, g: number, b: number) {
	const a = [r, g, b].map(function (v) {
		v /= 255;
		return v <= 0.03928
			? v / 12.92
			: Math.pow((v + 0.055) / 1.055, 2.4);
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrastAcceptable(hexColor1: string, hexColor2: string, largeText: boolean = false) {
	// read the colors and transform them into rgb format
	const color1rgb = hexToRgb(hexColor1);
	const color2rgb = hexToRgb(hexColor2);

	// calculate the relative luminance
	if (!color1rgb || !color2rgb) return 0;
	const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b);
	const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b);

	// calculate the color contrast ratio
	const ratio = color1luminance > color2luminance
		? ((color2luminance + 0.05) / (color1luminance + 0.05))
		: ((color1luminance + 0.05) / (color2luminance + 0.05));

	// AA Ratios
	const acceptedRatio = largeText ? 1 / 3 : 1 / 4.5;

	return ratio < acceptedRatio;
}

function textOnColorHex(hexColor: string) {
	return contrastAcceptable(hexColor, lightDefaultHex) ? lightDefaultHex : darkDefaultHex;
}

function textOnColorRgbValues(hexColor: string) {
	return contrastAcceptable(hexColor, lightDefaultHex) ? lightDefaultRgbValues : darkDefaultRgbValues;
}

export {
	lightDefaultHex,
	darkDefaultHex,
	lightDefaultRgbValues,
	darkDefaultRgbValues,
	hexToRgbValues,
	rgbValuesToHex,
	contrastAcceptable,
	textOnColorHex,
	textOnColorRgbValues,
};
