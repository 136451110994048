import { ref, reactive, watch, unref, type MaybeRef, type Component } from 'vue';
import type { MediaActionKey } from '@/composables/useMedia';
import {
	isMediaGallery,
	type Media,
	type MediaConversion,
	type MediaGallery,
	type MediaTags,
} from '@/types/media';
import { type MediaFileType, type MediaConversionSize } from '@teamworksdev/influencer-core';
import type { Gallery } from '@/types/galleries';

export interface MediaComponent {
	component: Component;
	attrs: Record<string, unknown>;
}

export class MediaPreview implements Media {
	uuid: string;
	file_name: string;
	mime_type: string;
	file_size: number;
	file_type: MediaFileType;
	conversions: Partial<Record<MediaConversionSize, MediaConversion>>
		& { original: NonNullableFields<MediaConversion> };

	gallery?: Pick<Gallery, 'uuid' | 'title' | 'category'>;
	tags?: MediaTags;

	constructor(data: Media | MediaGallery) {
		this.uuid = data.uuid;
		this.file_name = data.file_name;
		this.mime_type = data.mime_type;
		this.file_size = data.file_size;
		this.file_type = data.file_type;
		this.conversions = data.conversions;
		this.tags = isMediaGallery(data) ? data.tags : undefined;
		this.gallery = isMediaGallery(data) ? data.gallery : undefined;
	}
}

interface UseMediaPreviewDialogState {
	isOpen: boolean;
	currentIndex: number;
	actionsMenuItems: MediaActionKey[] | undefined;
	download: boolean | undefined;
	enableIncrement: boolean | undefined;
	media: Array<MediaPreview | undefined> | undefined;
	onViewDetails: ((mediaUuid: string) => void) | undefined;
}

const DEFAULT_STATE: UseMediaPreviewDialogState = {
	isOpen: false,
	currentIndex: 0,
	actionsMenuItems: undefined,
	download: undefined,
	enableIncrement: undefined,
	media: undefined,
	onViewDetails: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseMediaPreviewDialogState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseMediaPreviewDialogOptions {
	actionsMenuItems?: MaybeRef<UseMediaPreviewDialogState['actionsMenuItems']>;
	download?: MaybeRef<UseMediaPreviewDialogState['download']>;
	enableIncrement?: MaybeRef<UseMediaPreviewDialogState['enableIncrement']>;
	onViewDetails?: MaybeRef<UseMediaPreviewDialogState['onViewDetails']>;
}

export function useMediaPreviewDialog(options?: UseMediaPreviewDialogOptions) {
	function open(media: Array<Media | MediaGallery | undefined>, index?: number) {
		state.actionsMenuItems = unref(options?.actionsMenuItems);
		state.download = unref(options?.download);
		state.enableIncrement = unref(options?.enableIncrement);
		state.onViewDetails = unref(options?.onViewDetails);

		state.media = media.map((m) => m ? new MediaPreview(m) : undefined);
		state.currentIndex = index ?? 0;

		state.isOpen = true;
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
